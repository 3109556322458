// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 用户添加自选股 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /stock/my/add */
export async function stockMyAddUsingPOST(body: API.StockAddReq, options?: { [key: string]: any }) {
  return request<API.BaseResultstring>('/stock/my/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 前端获取用户自选股 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/my/list */
export async function stockMyListUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListStockInfoResp>('/stock/my/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 用户删除自选股 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /stock/my/reduce */
export async function stockMyReduceUsingPOST(
  body: API.StockReduceReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/stock/my/reduce', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 用户编辑自选股 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /stock/my/sort */
export async function stockMySortUsingPOST(
  body: API.StockSortReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/stock/my/sort', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取股票调研活动 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/notice/list */
export async function stockNoticeListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockNoticeListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListNoticeInfoResp>('/stock/notice/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取股票互动资讯(问董秘) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock/qa/list */
export async function stockQaListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockQaListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListQaInfoResp>('/stock/qa/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
