// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取用户订阅推送关系 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /stock-case/get-notice-sub */
export async function stockCaseGetNoticeSubUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseGetNoticeSubUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStockChannelNoticeSubResp>('/stock-case/get-notice-sub', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 订阅/取消案例推送 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /stock-case/sub-notice */
export async function stockCaseSubNoticeUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.stockCaseSubNoticeUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/stock-case/sub-notice', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
