// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取策略档案信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy/detail/info */
export async function strategyDetailInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyDetailInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStrategyDetailInfoResp>('/strategy/detail/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取策略sku信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy/info */
export async function strategyInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStrategySkuResp>('/strategy/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 策略通道列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy/passageway/list */
export async function strategyPassagewayListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyPassagewayListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStrategyPassagewayResp>('/strategy/passageway/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 策略收益详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy/profit/info */
export async function strategyProfitInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyProfitInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStrategyProfitResp>('/strategy/profit/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 策略列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy/strategy/list */
export async function strategyStrategyListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyStrategyListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStrategyInfoResp>('/strategy/strategy/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 策略交易列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy/trade/list */
export async function strategyTradeListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategyTradeListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStrategyTradeResp>('/strategy/trade/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
