// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取智能推荐的短视频列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /video/ai_rec_video_list */
export async function videoAiRecVideoListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.videoAiRecVideoListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListSsShortVideoResp>('/video/ai_rec_video_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 短视频发表评论 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /video/comment */
export async function videoCommentUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.videoCommentUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/video/comment', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 短视频评论列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /video/commentList */
export async function videoCommentListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.videoCommentListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListShortVideoCommentListResp>('/video/commentList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 视频点赞/取消点赞 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /video/like/or/unlike */
export async function videoLikeOrUnlikeUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.videoLikeOrUnlikeUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/video/like/or/unlike', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取老师的短视频列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /video/video_list */
export async function videoVideoListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.videoVideoListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListSsShortVideoResp>('/video/video_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
