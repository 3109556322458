// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取一键登录凭证 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /wx/certificate */
export async function wxCertificateUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultOneKeyLoginCertificate>('/wx/certificate', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取jsapi签名 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /wx/jsapi/signature */
export async function wxJsapiSignatureUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.wxJsapiSignatureUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultWxJsApiSignature>('/wx/jsapi/signature', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户的微信信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /wx/me */
export async function wxMeUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultWxInfo>('/wx/me', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 一键注册 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /wx/oneKeyRegister */
export async function wxOneKeyRegisterUsingPOST(
  body: API.TokenValidateReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultGenerateTokenResp>('/wx/oneKeyRegister', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** pc客户端微信登录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /wx/pc/login */
export async function wxPcLoginUsingPOST(body: API.WxLoginReq, options?: { [key: string]: any }) {
  return request<API.BaseResultGenerateTokenResp>('/wx/pc/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 微信注册 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /wx/wxRegister */
export async function wxWxRegisterUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.wxWxRegisterUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultGenerateTokenResp>('/wx/wxRegister', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
