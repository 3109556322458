// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 发放积分 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /order_point/send */
export async function orderPointSendUsingPOST(
  body: API.SendOrderPointReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/order_point/send', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取发放积分结果 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /order_point/send/result/get */
export async function orderPointSendResultGetUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.orderPointSendResultGetUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultOrderPointSendResp>('/order_point/send/result/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
