// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取直播室详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /chat_room/${param0} */
export async function chatRoomChatIdUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatRoomChatIdUsingGETParams,
  options?: { [key: string]: any },
) {
  const { chatId: param0, ...queryParams } = params;
  return request<API.BaseResultChatRoomInfoResp>(`/chat_room/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取聊天室点赞数量 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /chat_room/like */
export async function chatRoomLikeUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatRoomLikeUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultChatRoomLikeResp>('/chat_room/like', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取聊天室处理人老师关联信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /chat_room/manager/analyst/info */
export async function chatRoomManagerAnalystInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatRoomManagerAnalystInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListChatManagerAnalystResp>('/chat_room/manager/analyst/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取直播室公告列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
# **接口权限** 
 接口需要用户对应服务的权限，curl请求示例： 
  ```
  curl -X GET --header accept: application/json http://localhost:8107/chat_room/notice?roomId=roomId
  ```
 GET /chat_room/notice */
export async function chatRoomNoticeUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatRoomNoticeUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultChatNoticeResp>('/chat_room/notice', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取聊天室推送消息订阅信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /chat_room/notice/sub/info */
export async function chatRoomNoticeSubInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatRoomNoticeSubInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultImChatNoticeSubInfo>('/chat_room/notice/sub/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 配置聊天室推送消息订阅 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /chat_room/notice/sub/set */
export async function chatRoomNoticeSubSetUsingPOST(
  body: API.CreateOrUpdateChatNoticeSubReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/chat_room/notice/sub/set', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
