// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 使用体验券开始模拟实盘交易 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /strategy-simulate/coupon/create */
export async function strategySimulateCouponCreateUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategySimulateCouponCreateUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/strategy-simulate/coupon/create', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 开始模拟实盘交易 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /strategy-simulate/create */
export async function strategySimulateCreateUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategySimulateCreateUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/strategy-simulate/create', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取模拟实盘交易信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-simulate/get */
export async function strategySimulateGetUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultStrategySimulateResp>('/strategy-simulate/get', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取模拟实盘通道信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-simulate/passageways */
export async function strategySimulatePassagewaysUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListStrategyPassagewayStatisticsResp>(
    '/strategy-simulate/passageways',
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** 获取模拟实盘持仓 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-simulate/position */
export async function strategySimulatePositionUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategySimulatePositionUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStrategyPassagewayTransactionOrderResp>(
    '/strategy-simulate/position',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 获取用户模拟实盘体验记录列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /strategy-simulate/record/list */
export async function strategySimulateRecordListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.strategySimulateRecordListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStrategySimulationRecordResp>('/strategy-simulate/record/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 终止模拟实盘交易 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /strategy-simulate/terminate */
export async function strategySimulateTerminateUsingPOST(options?: { [key: string]: any }) {
  return request<API.BaseResultVoid>('/strategy-simulate/terminate', {
    method: 'POST',
    ...(options || {}),
  });
}
