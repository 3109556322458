// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 创建主订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /orders */
export async function ordersUsingPOST(
  body: API.CreateMainOrderReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCreateMainOrderResp>('/orders', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 强制授权广告单绑定unionId # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /orders/ad/pay/click/bind */
export async function ordersAdPayClickBindUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersAdPayClickBindUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/orders/ad/pay/click/bind', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 广告单绑定unionId # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /orders/ad/unionId */
export async function ordersAdUnionIdUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersAdUnionIdUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/orders/ad/unionId', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取苹果内购配置 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/appStore/inApps/config */
export async function ordersAppStoreInAppsConfigUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultAppStoreInAppsConfigResp>('/orders/appStore/inApps/config', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 苹果内购验证票据 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /orders/appStore/verifyReceipt */
export async function ordersAppStoreVerifyReceiptUsingPOST(
  body: API.VerifyAppStoreReceiptReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAppStoreVerifyReceiptResp>('/orders/appStore/verifyReceipt', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 根据sku查询订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/checkBySku */
export async function ordersCheckBySkuUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersCheckBySkuUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultBasicOrderInfo>('/orders/checkBySku', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取订单明细 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/detail/${param0} */
export async function ordersDetailOrderNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersDetailOrderNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  const { orderNumber: param0, ...queryParams } = params;
  return request<API.BaseResultOrderDetailResp>(`/orders/detail/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 回访问卷提交保存 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /orders/feedback/create */
export async function ordersFeedbackCreateUsingPOST(
  body: API.FeedbackReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/orders/feedback/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取用户二三四档已完成订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/finish */
export async function ordersFinishUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListFeedbackResp>('/orders/finish', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查询用户五分钟内的相同挡位的订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/finishedOrder */
export async function ordersFinishedOrderUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersFinishedOrderUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultRsCourseOrder>('/orders/finishedOrder', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 检查sku订单是否存在 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/has-paid-order-by-sku */
export async function ordersHasPaidOrderBySkuUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersHasPaidOrderBySkuUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultboolean>('/orders/has-paid-order-by-sku', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询主订单列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/list */
export async function ordersListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListOrderDtoResp>('/orders/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询我的订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/my */
export async function ordersMyUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersMyUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListOrderSkuInfoResp>('/orders/my', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据订单number查询订单信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/order-info-by-number */
export async function ordersOrderInfoByNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersOrderInfoByNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultOrderStatusInfo>('/orders/order-info-by-number', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 我的协议 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/orderSign */
export async function ordersOrderSignUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersOrderSignUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListOrderSignResp>('/orders/orderSign', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取支付方式 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/pay_type */
export async function ordersPayTypeUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersPayTypeUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListPayTypeResp>('/orders/pay_type', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 预下单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /orders/pre */
export async function ordersPreUsingPOST(
  body: API.PreCreateMainOrderReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultPreCreateMainOrderResp>('/orders/pre', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 创建营销订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /orders/promotion */
export async function ordersPromotionUsingPOST(
  body: API.CreatePromotionOrderReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCreatePayableSubOrderResp>('/orders/promotion', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 创建营销H5订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /orders/promotion/h5 */
export async function ordersPromotionH5UsingPOST(
  body: API.CreatePromotionOrderReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCreatePayableSubOrderResp>('/orders/promotion/h5', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询我的退款单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/refund/my */
export async function ordersRefundMyUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListOrderRefundSkuInfoResp>('/orders/refund/my', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 确认退费确认书 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /orders/refund/read */
export async function ordersRefundReadUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersRefundReadUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/orders/refund/read', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 前端回访问卷-获取订单详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/sku/detail */
export async function ordersSkuDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersSkuDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultOrderSkuDetailResp>('/orders/sku/detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 创建可支付的子订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /orders/sub */
export async function ordersSubUsingPOST(
  body: API.CreatePayableSubOrderReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCreatePayableSubOrderResp>('/orders/sub', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取用户未签字的订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/unsigned */
export async function ordersUnsignedUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersUnsignedUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListUnSignedOrderResp>('/orders/unsigned', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询用户订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/user */
export async function ordersUserUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersUserUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListOrderSkuInfoResp>('/orders/user', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询用户营销订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /orders/user/promotion */
export async function ordersUserPromotionUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.ordersUserPromotionUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListPromotionOrderInfoResp>('/orders/user/promotion', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
