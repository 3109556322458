// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 用户手机号登录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /auth/mobile/login */
export async function authMobileLoginUsingPOST(
  body: API.MobileLoginReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultMobileLoginResp>('/auth/mobile/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 刷新AccessToken # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /auth/token/refresh */
export async function authTokenRefreshUsingPOST(
  body: API.SessionLoginReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultAccessToken>('/auth/token/refresh', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
