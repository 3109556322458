import * as React from 'react';
import { Router, Route, Routes, withRouter, useRoutes , browserHistory } from 'react-router-dom';
import NoMatch from '@/components/NoMatch';
import PageLoading from '@/components/BaseComponent/PageLoading';
import { getUserAgent } from '@/util/handleUrlParams.ts'


/**
 *
 * 渲染路由
 * @class RouterUI
 * @extends {BaseComponent<IProps>}
 */
const RouterUI  = (props) => {
    let { routers } = props;
  /**
   * 生成router
   * @param {*} routers
   * @param {*} container
   * @param {*} recur 是否递归
   */
  const renderRouter = (routers = []) =>
    routers.map(router => {
      let { path, exact, unAuth, children} = router;
      if (unAuth) { }
      return (
        <Route
          key={path}
          path={path}
          element={renderPage(router)}
        >  {
            children?.map(item => <Route key={item.path} path={item.path} element={<item.element />} />)
        }

        </Route>
      );
    });

    const renderPage = (router) => {
    const { element, path, loadingFallback } = router;
    let Page = element;
   
    return (
      <React.Suspense fallback={loadingFallback || <PageLoading /> } key={path}>
        <Page />
      </React.Suspense>
    );
  };


    return (
      <Routes>
        {renderRouter(routers)}
        <Route component={NoMatch} />
      </Routes>

    );
}

export default RouterUI;
