// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 发布评论 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /article/comment */
export async function articleCommentUsingPOST(
  body: API.SubmitArticleCommentReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultArticleCommentItem>('/article/comment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取文章详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /article/info */
export async function articleInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.articleInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultArticleInfoResp>('/article/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 文章点赞（取消） # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /article/like */
export async function articleLikeUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.articleLikeUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/article/like', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 每日股评 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /article/list */
export async function articleListUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.articleListUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListArticleInfoResp>('/article/list', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
