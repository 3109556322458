// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 创建客户回测 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /simulation-back-test/create */
export async function simulationBackTestCreateUsingPOST(
  body: API.CreateBackTestReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStrategyBackTestResp>('/simulation-back-test/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除客户回测 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /simulation-back-test/delete */
export async function simulationBackTestDeleteUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.simulationBackTestDeleteUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/simulation-back-test/delete', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取客户回测详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /simulation-back-test/detail */
export async function simulationBackTestDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.simulationBackTestDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStrategyBackTestResp>('/simulation-back-test/detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取模拟回测资金走势 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /simulation-back-test/funds */
export async function simulationBackTestFundsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.simulationBackTestFundsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStrategyFundTrendResp>('/simulation-back-test/funds', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取客户回测列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /simulation-back-test/my-list */
export async function simulationBackTestMyListUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListStrategyBackTestResp>('/simulation-back-test/my-list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取模拟回测通道详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /simulation-back-test/passageways-detail */
export async function simulationBackTestPassagewaysDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.simulationBackTestPassagewaysDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListStrategyPassagewayStatisticsResp>(
    '/simulation-back-test/passageways-detail',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}
