import {Space, SpinLoading} from "antd-mobile";
import React from "react";



const PageLoading = ()=>{


  return <Space direction='vertical' justify='center' align='center' block style={{'backgroundColor': '#ffffff', 'height': '100vh','width': '100vw' }}>
    {/*<SpinLoading style={{ '--size': '48px' }} />*/}
  </Space>
}


export default PageLoading

