import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from '@/App';
import { BrowserRouter } from "react-router-dom";

import '@/assets/css/index.less';
import './index.less';
import ErrorBoundary from './components/ErrorBoundary';

import 'antd-mobile/es/global'
/*import VConsole from 'vconsole';*/
if( ENV !== 'prod' || location.search.indexOf('debug') > -1){
  var vConsole = new window.VConsole();
}
try {

  const rootElement = document.getElementById('root');

  const renderApp = () => {
    ReactDOM.render(
      <React.StrictMode>
          <ErrorBoundary>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
          </ErrorBoundary>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  };
  renderApp();
} catch (e) {
  console.log('e');
}
