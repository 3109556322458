// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取STS临时token # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /vod/sts/token */
export async function vodStsTokenUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultStsTokenResp>('/vod/sts/token', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取播放凭证 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /vod/video/auth */
export async function vodVideoAuthUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.vodVideoAuthUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/vod/video/auth', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
