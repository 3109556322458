// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取房间信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/${param0}/info */
export async function liveRoomRoomIdInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomRoomIdInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  const { roomId: param0, ...queryParams } = params;
  return request<API.BaseResultLiveRoomResp>(`/live/room/${param0}/info`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取直播场次公告列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/${param0}/notice */
export async function liveRoomSceneIdNoticeUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomSceneIdNoticeUsingGETParams,
  options?: { [key: string]: any },
) {
  const { sceneId: param0, ...queryParams } = params;
  return request<API.BaseResultListRoomSceneNoticeResp>(`/live/room/${param0}/notice`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取直播场次点赞数量 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/like */
export async function liveRoomLikeUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomLikeUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultRoomSceneLikeResp>('/live/room/like', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取直播室列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/list */
export async function liveRoomListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListLiveRoomResp>('/live/room/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取抽奖活动详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/lottery/detail */
export async function liveRoomLotteryDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomLotteryDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultLotteryDetailResp>('/live/room/lottery/detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 参与抽奖活动 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /live/room/lottery/participate */
export async function liveRoomLotteryParticipateUsingPOST(
  body: API.CreateParticipateLotteryReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/live/room/lottery/participate', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取有效抽奖活动 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/lottery/valid */
export async function liveRoomLotteryValidUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomLotteryValidUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListRoomLotteryResp>('/live/room/lottery/valid', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取中奖列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/lottery/winner */
export async function liveRoomLotteryWinnerUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomLotteryWinnerUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListLotteryWinnerResp>('/live/room/lottery/winner', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取单个中奖明细 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/prize/detail */
export async function liveRoomPrizeDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomPrizeDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultMyLotteryPrizeResp>('/live/room/prize/detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取我的奖品列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/prize/my */
export async function liveRoomPrizeMyUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomPrizeMyUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListMyLotteryPrizeResp>('/live/room/prize/my', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取营销直播室场次信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/promotionScene */
export async function liveRoomPromotionSceneUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomPromotionSceneUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultRoomSceneResp>('/live/room/promotionScene', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取场次信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/scene */
export async function liveRoomSceneUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomSceneUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultRoomSceneResp>('/live/room/scene', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取场次商品详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/store/detail */
export async function liveRoomStoreDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomStoreDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultRoomSceneStoreResp>('/live/room/store/detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取直播视频详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/video/info */
export async function liveRoomVideoInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomVideoInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultLiveRoomVideoResp>('/live/room/video/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取直播回放列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/video/list */
export async function liveRoomVideoListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomVideoListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListLiveRoomVideoResp>('/live/room/video/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取营销直播室回放列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /live/room/video/promotionList */
export async function liveRoomVideoPromotionListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.liveRoomVideoPromotionListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListLiveRoomVideoResp>('/live/room/video/promotionList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
