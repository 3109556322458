// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 用户签到 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /checkIn */
export async function checkInUsingPOST(options?: { [key: string]: any }) {
  return request<API.BaseResultUserCheckInResp>('/checkIn', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 获取用户签到记录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /checkIn/record */
export async function checkInRecordUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.checkInRecordUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListUserCheckInRecordItem>('/checkIn/record', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户签到状态 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /checkIn/status */
export async function checkInStatusUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultUserCheckInStatusResp>('/checkIn/status', {
    method: 'GET',
    ...(options || {}),
  });
}
