// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取微盟账号 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /weimob/get-wid */
export async function weimobGetWidUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultWeimobUserResp>('/weimob/get-wid', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取我的订单积分活动领取列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /weimob/order-point/list */
export async function weimobOrderPointListUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListMyOrderPointActivityResp>('/weimob/order-point/list', {
    method: 'GET',
    ...(options || {}),
  });
}
