// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 按栏目获取文章列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /article/course */
export async function articleCourseUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.articleCourseUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListArticleCourseBriefResp>('/article/course', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量获取文章列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /article/course/batch */
export async function articleCourseBatchUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.articleCourseBatchUsingPOSTParams,
  body: API.BatchReqstring,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListArticleCourseBriefResp>('/article/course/batch', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取文章栏目 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /article/course/columns */
export async function articleCourseColumnsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.articleCourseColumnsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultArticleSeriesResp>('/article/course/columns', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 三档按栏目获取文章列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /article/course/l3 */
export async function articleCourseL3UsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.articleCourseL3UsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListArticleCourseBriefResp>('/article/course/l3', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 前端获取文章详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /article/course/number/${param0} */
export async function articleCourseNumberNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.articleCourseNumberNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  const { number: param0, ...queryParams } = params;
  return request<API.BaseResultArticleCourseDetailResp>(`/article/course/number/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取栏目列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /article/course/series */
export async function articleCourseSeriesUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.articleCourseSeriesUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListArticleSeriesResp>('/article/course/series', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 按栏目股票代码获取文章列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /article/course/stock */
export async function articleCourseStockUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.articleCourseStockUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListArticleCourseBriefResp>('/article/course/stock', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取文章标签列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /article/course/tags */
export async function articleCourseTagsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.articleCourseTagsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListArticleTagResp>('/article/course/tags', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 按栏目获取精彩回顾 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /article/course/top */
export async function articleCourseTopUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.articleCourseTopUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListArticleCourseBriefResp>('/article/course/top', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
