// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** App用户绑定微信 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/user/bindWx */
export async function appUserBindWxUsingPOST(
  body: API.UserBindWxReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultBindWxResp>('/app/user/bindWx', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取用户昵称配置 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /app/user/getNickNameConf */
export async function appUserGetNickNameConfUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultUserNickNameConfResp>('/app/user/getNickNameConf', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取初始用户头像列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /app/user/getUserAvatarList */
export async function appUserGetUserAvatarListUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListstring>('/app/user/getUserAvatarList', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取App用户详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /app/user/getUserInfo */
export async function appUserGetUserInfoUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultAppUserInfoResp>('/app/user/getUserInfo', {
    method: 'GET',
    ...(options || {}),
  });
}

/** App用户解绑微信 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/user/unbindWx */
export async function appUserUnbindWxUsingPOST(options?: { [key: string]: any }) {
  return request<API.BaseResultBindWxResp>('/app/user/unbindWx', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 更新App用户昵称与头像信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/user/updateUserNickNameOrAvatar */
export async function appUserUpdateUserNickNameOrAvatarUsingPOST(
  body: API.UpdateAppUserInfoReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/app/user/updateUserNickNameOrAvatar', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** App更新用户微信信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /app/user/updateWxInfo */
export async function appUserUpdateWxInfoUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.appUserUpdateWxInfoUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultUcWxExt>('/app/user/updateWxInfo', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
