import React, { useState, useReducer, useEffect } from 'react';
import { useSearchParams, useLocation, useParams } from "react-router-dom";
import qs from 'qs';
import base64url from 'base64-url';
import routerConfig from './router/index';
import RouterUI from '@/router/RouterUI';
import api from "@/services/api/index";
import PageLoading from '@/components/BaseComponent/PageLoading';
import AppContext from './common/AppContext';
import commonHelper from '@/utils/commonHelper';
import commonSetting from '@/config/commonSetting'
import { getUserAgent } from '@/util/handleUrlParams'
import {searchPath} from '@/util/stringUtil.ts';

function reducer(prevState, action) {
  // reducer will read the value from the latest render
  const { count: prevCount } = prevState;
  const { type, payload } = action;
  if (type === "tokens") {
    return { ...prevState, tokens: payload };
  } else if (type === "loading") {
    return { ...prevState, loading: payload };
  } else if (type === "wxInfo") {
    return { ...prevState, wxInfo: payload };
  } else if (type === "userInfo") {
    return { ...prevState, userInfo: payload };
  }  else if (type === "redirect") {
    return { ...prevState, redirect: payload };
  } else {
    throw new Error();
  }
}
const whitePageList = ["/mina-h5-w/:page", "/h-m/:page", "/h-k/:page", "/h-w/:page", "/h-l/:page"]
const App = (props) => {
    const isWechatEnv = getUserAgent().get("isWechat")
    const [state, dispatch] = useReducer(reducer, {
    accountType: commonSetting.ACCOUNT_TYPE,
    productType: commonSetting.PRODUCT_TYPE,
    firstHref: window.location.href,
    loading: true
  });
  let location = useLocation();
  console.log('location', location)
  useEffect(() => {
      // window.addEventListener('pageshow', function(e) {
      //     // 通过persisted属性判断是否存在 BF Cache（缓存）
      //     if (e.persisted) {
      //         window.location.reload()
      //         alert(window.performance.navigation.type)
      //     }}
      // );
      if(!searchPath(['/pc-b/:page','/bds-h5/:page'], location.pathname)){
          document.body.classList.add("mobile")
      }
  }, [])
  return (
    <>
      <AppContext.Provider value={{ ...state, dispatch: dispatch }}>
       <RouterUI routers={routerConfig.routes} />
      </AppContext.Provider>
    </>
  );
}

export default App;
