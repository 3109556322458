// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 根据题目获取选项信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /survey/choice */
export async function surveyChoiceUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.surveyChoiceUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListSsSurveyChoice>('/survey/choice', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据token为用户创建评测 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /survey/createCustomerEvaluation */
export async function surveyCreateCustomerEvaluationUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultEvaluationDetailResp>('/survey/createCustomerEvaluation', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取测评选项 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /survey/evaluation/answer/get */
export async function surveyEvaluationAnswerGetUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.surveyEvaluationAnswerGetUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultEvaluationAnswerResp>('/survey/evaluation/answer/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 保存测评选项 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /survey/evaluation/answer/save */
export async function surveyEvaluationAnswerSaveUsingPOST(
  body: API.SaveEvaluationAnswerReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/survey/evaluation/answer/save', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 提交客户评估 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /survey/evaluation/finish */
export async function surveyEvaluationFinishUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.surveyEvaluationFinishUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/survey/evaluation/finish', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 生成测评pdf # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /survey/generateEvaluationPdf */
export async function surveyGenerateEvaluationPdfUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.surveyGenerateEvaluationPdfUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<any>('/survey/generateEvaluationPdf', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户测评 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /survey/getCustomerEvaluation */
export async function surveyGetCustomerEvaluationUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultEvaluationDetailResp>('/survey/getCustomerEvaluation', {
    method: 'GET',
    ...(options || {}),
  });
}

/** H5生成测评pdf # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /survey/getEvaluationPdf */
export async function surveyGetEvaluationPdfUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.surveyGetEvaluationPdfUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<any>('/survey/getEvaluationPdf', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 我的测评 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /survey/myEvaluation */
export async function surveyMyEvaluationUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultEvaluationResp>('/survey/myEvaluation', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取用户实名信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /survey/realInfo */
export async function surveyRealInfoUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultCustomerEvaluationResp>('/survey/realInfo', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 重新测评 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /survey/redoEvaluation */
export async function surveyRedoEvaluationUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.surveyRedoEvaluationUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultEvaluationDetailResp>('/survey/redoEvaluation', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 提交客户评估 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /survey/submitEvaluation */
export async function surveySubmitEvaluationUsingPOST(
  body: API.SubmitEvaluationReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultboolean>('/survey/submitEvaluation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 提交客户评估基本信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /survey/submitEvaluationUserInfo */
export async function surveySubmitEvaluationUserInfoUsingPOST(
  body: API.EvaluationUserInfoReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultboolean>('/survey/submitEvaluationUserInfo', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取题目列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /survey/topic */
export async function surveyTopicUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.surveyTopicUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListSsSurveyTopic>('/survey/topic', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户测评次数 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /survey/userEvaluationCount */
export async function surveyUserEvaluationCountUsingPOST(options?: { [key: string]: any }) {
  return request<API.BaseResultEvaluationKey>('/survey/userEvaluationCount', {
    method: 'POST',
    ...(options || {}),
  });
}
