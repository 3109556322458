// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取聊天室热度 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /chat_dialog/degree */
export async function chatDialogDegreeUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatDialogDegreeUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultlong>('/chat_dialog/degree', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 互动列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
# **接口权限** 
 接口需要用户对应服务的权限，curl请求示例： 
  ```
  curl -X GET --header accept: application/json http://localhost:8107/chat_dialog/list?roomId=roomId
  ```
 GET /chat_dialog/list */
export async function chatDialogListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatDialogListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListImMsgRespBody>('/chat_dialog/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 发送互动消息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /chat_dialog/send */
export async function chatDialogSendUsingPOST(
  body: API.SendImChatDialogReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/chat_dialog/send', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取互动语音翻译内容 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /chat_dialog/trans/content */
export async function chatDialogTransContentUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatDialogTransContentUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultTransContentResp>('/chat_dialog/trans/content', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
