// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 通过skuId获取sku # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /sku/${param0} */
export async function skuSkuIdUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.skuSkuIdUsingGETParams,
  options?: { [key: string]: any },
) {
  const { skuId: param0, ...queryParams } = params;
  return request<API.BaseResultSkuInfo>(`/sku/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 通过skuId获取sku详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /sku/${param0}/detail */
export async function skuSkuIdDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.skuSkuIdDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  const { skuId: param0, ...queryParams } = params;
  return request<API.BaseResultSkuDetailResp>(`/sku/${param0}/detail`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 通过skuId获取sku（白名单） # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /sku/findBySkuId/${param0} */
export async function skuFindBySkuIdSkuIdUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.skuFindBySkuIdSkuIdUsingGETParams,
  options?: { [key: string]: any },
) {
  const { skuId: param0, ...queryParams } = params;
  return request<API.BaseResultSkuInfo>(`/sku/findBySkuId/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 通过skuNumber获取sku（白名单） # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /sku/info-by-number */
export async function skuInfoByNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.skuInfoByNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultSkuInfo>('/sku/info-by-number', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 通过skuNumber获取sku # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /sku/number/${param0} */
export async function skuNumberSkuNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.skuNumberSkuNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  const { skuNumber: param0, ...queryParams } = params;
  return request<API.BaseResultSkuInfo>(`/sku/number/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 通过skuId获取sku详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /sku/number/${param0}/detail */
export async function skuNumberSkuNumberDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.skuNumberSkuNumberDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  const { skuNumber: param0, ...queryParams } = params;
  return request<API.BaseResultSkuDetailResp>(`/sku/number/${param0}/detail`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取营销sku详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /sku/promotion/info */
export async function skuPromotionInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.skuPromotionInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultPromotionSkuInfo>('/sku/promotion/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取营销sku列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /sku/promotion/list */
export async function skuPromotionListUsingPOST(
  body: API.SkuNumberReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListPromotionSkuInfo>('/sku/promotion/list', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 通过skuId获取sku策略信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /sku/strategy/info */
export async function skuStrategyInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.skuStrategyInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultSkuStrategy>('/sku/strategy/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
