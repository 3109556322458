// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 开发票 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /invoice */
export async function invoiceUsingPOST(
  body: API.ApplyOrderInvoiceReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/invoice', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取发票 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /invoice/${param0} */
export async function invoiceOrderNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.invoiceOrderNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  const { orderNumber: param0, ...queryParams } = params;
  return request<API.BaseResultListGetInvoiceResp>(`/invoice/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 批量获取发票 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /invoice/batch */
export async function invoiceBatchUsingPOST(
  body: API.BatchReqstring,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultMapint>('/invoice/batch', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
