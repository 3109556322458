import request, { extend } from 'umi-request';
import api from '@/services/api/index';
import base64url from "base64-url";

import commonSetting from '@/config/commonSetting';
import commonHelper from '@/utils/commonHelper';
import {authTokenRefreshUsingPOST} from "../services/api/authController";

const loginPath = '/user/login';
let isRefreshing = false // 是否正在刷新
const subscribers = [] // 重试队列，每一项将是一个待执行的函数形式

const addSubscriber = listener => subscribers.push(listener)

// 执行被缓存等待的接口事件
const notifySubscriber = (newToken = '') => {
  subscribers.forEach(callback => callback(newToken))
  subscribers.length = 0
}
const clearToken = () => {
    localStorage.setItem('accessToken','')
    localStorage.setItem('refreshToken','')
}
// 刷新 token 请求
const refreshTokenRequest = async () => {
  const refreshToken  = window.localStorage.getItem('refreshToken')
  const accessToken  = window.localStorage.getItem('accessToken')
  try {
    const res = await api.authController.authTokenRefreshUsingPOST({refreshToken, accessToken})
    if(res.code === 0){
      localStorage.setItem('accessToken', res.data.value);
      notifySubscriber(res.data.value)
    }else if([81000008,81030002,81030010,81000010].includes(res.code )) { //token失效
     // message.info('请重新登录')
      clearToken()
      commonHelper.redirectWxAuthorizationUrl(encodeURIComponent(window.location.href))
    }

  } catch (e) {
    console.error('请求刷新 token 失败', e)
   clearToken()
   commonHelper.redirectWxAuthorizationUrl(encodeURIComponent(window.location.href))
  }
  isRefreshing = false
}

// 判断如何响应
function checkStatus(response, options) {
  const { url } = response
    const refreshToken  = window.localStorage.getItem('refreshToken')
      if(refreshToken){
        if (!isRefreshing ) {
          isRefreshing = true
          refreshTokenRequest()
        }
      }else {
        //message.info('请重新登录')
          clearToken()
          commonHelper.redirectWxAuthorizationUrl(encodeURIComponent(window.location.href))
        return
      }


  // 正在刷新 token，返回一个未执行 resolve 的 promise
  return new Promise(resolve => {
    addSubscriber(newToken => {
      const newOptions = {
        ...options,
        prefix: '',
        params: {},
        headers: {
          Authorization: 'Bearer ' + newToken
        }
      }
      resolve(request(url, newOptions))
    })
  })
}


const requestInstance = extend({
    timeout: 10000,
    headers: {
        mode:"cors",
    },
    errorHandler: (error) => {
        //  debugger
        throw error;
    },
})
requestInstance.interceptors.request.use(
    (url, options) => {
        const accessToken  = window.localStorage.getItem('accessToken' )
        if(accessToken){
            options.headers.Authorization = 'Bearer ' + accessToken
        }
        if(!url.startsWith('http')){
            if (options.mock && ENV !== 'prod') {
                url = commonSetting.MOCK_URL + url;
            } else {
                url = commonSetting.BASE_URL + url;
            }
        }
        return {
          url,
          options: { ...options},
        };
    }
);
/**
 * 响应拦截
 */
requestInstance.interceptors.response.use(
    // @ts-ignore
    async (response, options) => {
       // const data = await response.clone().json()
        console.log(response)

        if(response.status == 503){
            //message.error('服务器错误')
        }else if([400].includes(response.status)){
            // message.error(`请求错误：错误码${response.status}  traceid:${response.headers.get("x-b3-traceid")}`)
            console.log(`请求错误：错误码${response.status}  traceid:${response.headers.get("x-b3-traceid")}`)
        }else if([401, 403].includes(response.status)) {
            return checkStatus(response, options)
        }
        return response
    }
);
export default requestInstance
