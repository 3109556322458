// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 修改聊天室解盘点赞状态 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 PUT /chat_stream_msg/like */
export async function chatStreamMsgLikeUsingPUT(
  body: API.UpdateChatStreamLikeReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/chat_stream_msg/like', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 解盘列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
# **接口权限** 
 接口需要用户对应服务的权限，curl请求示例： 
  ```
  curl -X GET --header accept: application/json http://localhost:8107/chat_stream_msg/list?roomId=roomId
  ```
 GET /chat_stream_msg/list */
export async function chatStreamMsgListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatStreamMsgListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListWebChatStreamMessageResp>('/chat_stream_msg/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取播放凭证 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /chat_stream_msg/vod/video/auth */
export async function chatStreamMsgVodVideoAuthUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatStreamMsgVodVideoAuthUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultGetVideoPlayAuthResponse>('/chat_stream_msg/vod/video/auth', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
