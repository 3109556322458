// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 内容主视频发表评论 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /team/video/comment */
export async function teamVideoCommentUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.teamVideoCommentUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/team/video/comment', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 内容主视频信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /team/video/info */
export async function teamVideoInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.teamVideoInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultTeamShortVideoResp>('/team/video/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
