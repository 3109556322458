// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 客户策略详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /customer-strategy/detail */
export async function customerStrategyDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.customerStrategyDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCustomerStrategyDetailResp>('/customer-strategy/detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 通道总概览 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /customer-strategy/passageway/info */
export async function customerStrategyPassagewayInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.customerStrategyPassagewayInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCustomerPassagewayInfoResp>('/customer-strategy/passageway/info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 客户通道列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /customer-strategy/passageway/list */
export async function customerStrategyPassagewayListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.customerStrategyPassagewayListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListCustomerPassagewayResp>('/customer-strategy/passageway/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取策略收益曲线信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /customer-strategy/revenue/line */
export async function customerStrategyRevenueLineUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.customerStrategyRevenueLineUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListRatioResp>('/customer-strategy/revenue/line', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 客户持仓 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /customer-strategy/stock/list */
export async function customerStrategyStockListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.customerStrategyStockListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListCustomerStockDetailResp>('/customer-strategy/stock/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 策略订阅列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /customer-strategy/subscription/list */
export async function customerStrategySubscriptionListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.customerStrategySubscriptionListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStrategySubscriptionResp>(
    '/customer-strategy/subscription/list',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 策略模板消息列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /customer-strategy/template/list */
export async function customerStrategyTemplateListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.customerStrategyTemplateListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCustomerStrategyTemplateResp>('/customer-strategy/template/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
