// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取策略交易详细 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /back-test/strategy/trade-detail */
export async function backTestStrategyTradeDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.backTestStrategyTradeDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListCustomerTradeDetailResp>('/back-test/strategy/trade-detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取策略回撤统计 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /back-test/strategy/trade/statistics */
export async function backTestStrategyTradeStatisticsUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.backTestStrategyTradeStatisticsUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCustomerTradeStatisticsResp>(
    '/back-test/strategy/trade/statistics',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 获取策略回撤走势列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /back-test/trade/statistics/list */
export async function backTestTradeStatisticsListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.backTestTradeStatisticsListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListRatioResp>('/back-test/trade/statistics/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
