// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取聊天室问诊详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /chat_room/qa/${param0}/info */
export async function chatRoomQaMessageIdInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatRoomQaMessageIdInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  const { messageId: param0, ...queryParams } = params;
  return request<API.BaseResultChatQaInfoResp>(`/chat_room/qa/${param0}/info`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 催单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /chat_room/qa/${param0}/push */
export async function chatRoomQaMessageIdPushUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatRoomQaMessageIdPushUsingPOSTParams,
  options?: { [key: string]: any },
) {
  const { messageId: param0, ...queryParams } = params;
  return request<API.BaseResultstring>(`/chat_room/qa/${param0}/push`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取问诊室老师列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /chat_room/qa/analystList */
export async function chatRoomQaAnalystListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatRoomQaAnalystListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListSsAnalystInfo>('/chat_room/qa/analystList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 创建问诊消息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /chat_room/qa/create */
export async function chatRoomQaCreateUsingPOST(
  body: API.CreateChatRoomQAMessageReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCreatRoomQAResp>('/chat_room/qa/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取指定用户问诊室列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
# **接口权限** 
 接口需要用户对应服务的权限，curl请求示例： 
  ```
  curl -X GET --header accept: application/json http://localhost:8107/chat_room/qa/list?roomId=roomId
  ```
 GET /chat_room/qa/list */
export async function chatRoomQaListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatRoomQaListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListChatQaMessageResp>('/chat_room/qa/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取用户问诊剩余次数 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /chat_room/qa/qaLastCount */
export async function chatRoomQaQaLastCountUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.chatRoomQaQaLastCountUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultChatRoomQACountResp>('/chat_room/qa/qaLastCount', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 问诊满意度调查 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /chat_room/qa/research */
export async function chatRoomQaResearchUsingPOST(
  body: API.ChatRoomQAResearchReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/chat_room/qa/research', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
