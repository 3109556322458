// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 微信授权校验 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /wx/oauth2/${param0} */
export async function wxOauth2FilenameUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.wxOauth2FilenameUsingGETParams,
  options?: { [key: string]: any },
) {
  const { filename: param0, ...queryParams } = params;
  return request<string>(`/wx/oauth2/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 微信授权回调地址 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /wx/oauth2/redirect */
export async function wxOauth2RedirectUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.wxOauth2RedirectUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<string>('/wx/oauth2/redirect', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 微信授权回调地址 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /wx/oauth2/redirectUri */
export async function wxOauth2RedirectUriUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.wxOauth2RedirectUriUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<string>('/wx/oauth2/redirectUri', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 通过productType生成微信授权链接 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /wx/oauth2/url */
export async function wxOauth2UrlUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.wxOauth2UrlUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/wx/oauth2/url', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 通过redirectUri生成微信授权链接 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /wx/oauth2/url/redirect */
export async function wxOauth2UrlRedirectUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.wxOauth2UrlRedirectUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/wx/oauth2/url/redirect', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
