// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 通过number获取礼赠商品 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /gift/good/findByNumber */
export async function giftGoodFindByNumberUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.giftGoodFindByNumberUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultRsGiftGood>('/gift/good/findByNumber', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
