let BASE_URL, OSS_URL, GC_PROD_URL, GC_PAY_URL, GC_ACCESS_URL;
// @ts-ignore
console.log('ENV', ENV)
console.log('ENV', process.env)
const {NODE_ENV} = process.env;
let GS_TC_URL = 'http://gcts-wechat.beta.shrise.cn/';
let MOCK_URL = 'https://mock.apifox.com/m1/4291882-3934231-default';


let ACCOUNT_TYPE = 900;
const PRODUCT_TYPE = 173;
const COMPANY_TYPE = 45;
if(ENV === 'beta0'){
  //BASE_URL = 'http://10.0.27.188:8106';
  BASE_URL = 'https://api-web-beta0.shrise.cn';
  OSS_URL = 'https://tj-file.oss.shrise.cn/';
  GC_ACCESS_URL = "https://gc-access-beta1.shrise.cn/"
  GC_PROD_URL = 'https://gc-prod-beta0.shrise.cn/';
  GC_PAY_URL = 'https://gc-pay-beta0.shrise.cn/';
}
// @ts-ignore
else if (ENV === 'beta1') {
  BASE_URL = 'https://api-web-beta1.shrise.cn';
  OSS_URL = 'https://tj-file.oss.shrise.cn/';
  GC_ACCESS_URL = "https://gc-access-beta1.shrise.cn/"
  GC_PROD_URL = 'https://gc-prod-beta1.shrise.cn/';
  GC_PAY_URL = 'https://gc-pay-beta1.shrise.cn/';
  ACCOUNT_TYPE = 900;
}
// @ts-ignore
else if (ENV === 'beta2') {
  BASE_URL = 'https://api-web-beta2.shrise.cn';
  OSS_URL = 'https://tj-file.oss.shrise.cn/';
  // BASE_URL = 'https://api-admin-v2.shrise.cn';
  GC_ACCESS_URL = "https://gc-access-beta2.shrise.cn/"
  GC_PROD_URL = 'https://gc-prod-beta2.shrise.cn/';
  GC_PAY_URL = 'https://gc-pay-beta2.shrise.cn/';
}
else if (ENV === 'beta3') {
  BASE_URL = 'https://api-web-beta3.shrise.cn';
  OSS_URL = 'https://tj-file.oss.shrise.cn/';
  GC_ACCESS_URL = "https://gc-access-beta3.shrise.cn/"
  GC_PROD_URL = 'https://gc-prod-beta3.shrise.cn/';
  GC_PAY_URL = 'https://gc-pay-beta3.shrise.cn/';
}
// @ts-ignore
else if (ENV === 'beta4') {
  BASE_URL = 'https://api-web-beta4.shrise.cn';
  OSS_URL = 'https://tj-file.oss.shrise.cn/';
  // BASE_URL = 'https://api-admin-v2.shrise.cn';
  GC_ACCESS_URL = "https://gc-access-beta4.shrise.cn/"
  GC_PROD_URL = 'https://gc-prod-beta4.shrise.cn/';
  GC_PAY_URL = 'https://gc-pay-beta4.shrise.cn/';
}
else if (ENV === 'beta5') {
  BASE_URL = 'https://api-web-beta5.shrise.cn';
  OSS_URL = 'https://tj-file.oss.shrise.cn/';
  // BASE_URL = 'https://api-admin-v2.shrise.cn';
  GC_ACCESS_URL = "https://gc-access-beta5.shrise.cn/"
  GC_PROD_URL = 'https://gc-prod-beta5.shrise.cn/';
  GC_PAY_URL = 'https://gc-pay-beta5.shrise.cn/';
}
else {
  BASE_URL = 'https://api-web.cf69.cn';
  OSS_URL = 'https://tj-file.oss.shrise.cn/';
  GS_TC_URL = 'https://wxts.cf69.cn/'
  GC_ACCESS_URL = "https://gc-access.cf69.cn/"
  GC_PROD_URL = 'https://gc-prod.cf69.cn/';
  GC_PAY_URL = 'https://gc-pay.cf69.cn/';
}

if(NODE_ENV === 'development'){
  MOCK_URL = 'http://10.0.27.137:4523/m1/4291882-3934231-default';
}

export default {
  ACCOUNT_TYPE,
  MOCK_URL,
  PRODUCT_TYPE,
  GC_PAY_URL,
  GS_TC_URL,
  BASE_URL,
  OSS_URL,
  GC_ACCESS_URL,
  COMPANY_TYPE
}
