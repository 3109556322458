// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 获取月度积分记录 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/reward/month */
export async function usersRewardMonthUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.usersRewardMonthUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListUserRewardRecordItem>('/users/reward/month', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取月度积分汇总 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/reward/month/summary */
export async function usersRewardMonthSummaryUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultListUserRewardMonthSummaryItem>('/users/reward/month/summary', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取签到积分规则 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/reward/rules */
export async function usersRewardRulesUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultRewardSignInRulesResp>('/users/reward/rules', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取积分 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /users/reward/summary */
export async function usersRewardSummaryUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultlong>('/users/reward/summary', {
    method: 'GET',
    ...(options || {}),
  });
}
