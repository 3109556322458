// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 广告渠道关系绑定(白名单) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /convert/bind-union-convert */
export async function convertBindUnionConvertUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.convertBindUnionConvertUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/convert/bind-union-convert', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 广告渠道关系绑定 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /convert/bindUnionConvertInfo */
export async function convertBindUnionConvertInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.convertBindUnionConvertInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultVoid>('/convert/bindUnionConvertInfo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 进入小程序回传 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /convert/enterMiniProgram */
export async function convertEnterMiniProgramUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.convertEnterMiniProgramUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultstring>('/convert/enterMiniProgram', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 回传数据 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /convert/union_convert_info */
export async function convertUnionConvertInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.convertUnionConvertInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultNpWxUnionConvertInfo>('/convert/union_convert_info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
