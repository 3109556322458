import React, { ComponentClass } from 'react';

const AppContext = React.createContext();

export default AppContext;

export const { Consumer } = AppContext;

export const { Provider } = AppContext;

export function withAppContext(Com) {
  return (props) =>{

      return (
        <AppContext.Consumer>{context => <Com {...props} {...context} />}</AppContext.Consumer>
      );
    }

}

export const withAppContextDecorators = withAppContext;
