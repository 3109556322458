// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 创建策略客户 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /ra-customer/create */
export async function raCustomerCreateUsingPOST(options?: { [key: string]: any }) {
  return request<API.BaseResultstring>('/ra-customer/create', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 获取策略客户 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /ra-customer/get */
export async function raCustomerGetUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultstring>('/ra-customer/get', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 我的交易授权详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /ra-customer/trade-auth/my-info */
export async function raCustomerTradeAuthMyInfoUsingGET(options?: { [key: string]: any }) {
  return request<API.BaseResultMyTradeAuthResp>('/ra-customer/trade-auth/my-info', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 我的交易授权记录列表 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /ra-customer/trade-auth/my-record-list */
export async function raCustomerTradeAuthMyRecordListUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.raCustomerTradeAuthMyRecordListUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListMyTradeAuthRecordResp>(
    '/ra-customer/trade-auth/my-record-list',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 开通智投交易授权 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /ra-customer/trade-auth/open */
export async function raCustomerTradeAuthOpenUsingPOST(options?: { [key: string]: any }) {
  return request<API.BaseResultstring>('/ra-customer/trade-auth/open', {
    method: 'POST',
    ...(options || {}),
  });
}
