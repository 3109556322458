// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 一档栏目股票 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /recommend/stock/column */
export async function recommendStockColumnUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.recommendStockColumnUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultColumnStockResp>('/recommend/stock/column', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 一档栏目股票(股票池) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /recommend/stock/column/pool */
export async function recommendStockColumnPoolUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.recommendStockColumnPoolUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultColumnStockResp>('/recommend/stock/column/pool', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据栏目获取对应股票池信息 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /recommend/stock/getStockPoolInfo */
export async function recommendStockGetStockPoolInfoUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.recommendStockGetStockPoolInfoUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultRecommendStockTypeResp>('/recommend/stock/getStockPoolInfo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 一档栏目股票公告(股票池) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /recommend/stock/notice/pool */
export async function recommendStockNoticePoolUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.recommendStockNoticePoolUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListRecommendStockNoticeResp>('/recommend/stock/notice/pool', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 上榜股票 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /recommend/stock/rank */
export async function recommendStockRankUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.recommendStockRankUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStockInfoDto>('/recommend/stock/rank', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 上榜股票(股票池) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /recommend/stock/rank/pool */
export async function recommendStockRankPoolUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.recommendStockRankPoolUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.PageResultListStockInfoDto>('/recommend/stock/rank/pool', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 股票详情 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /recommend/stock/stockDetail */
export async function recommendStockStockDetailUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.recommendStockStockDetailUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStockDetailDto>('/recommend/stock/stockDetail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 股票详情(股票池) # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 GET /recommend/stock/stockDetail/pool */
export async function recommendStockStockDetailPoolUsingGET(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.recommendStockStockDetailPoolUsingGETParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultStockDetailDto>('/recommend/stock/stockDetail/pool', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
